var cookieNotification = {
    notification: $('.js-cookie-notification'),
    notificationButton: $('.js-cookie-notification-btn'),
    notificationCloseButton: $('.js-cookie-notification-btn-close'),

    init: function () {
        const self = this;
        if (!this.expiredDate || this.isExpired()) {
            setTimeout(function () {
                self.showNotification();
            }, 2000);
            this.createListeners();
        }
    },

    isExpired: function () {
        return this.expiredDate <= new Date().getTime();
    },
    get expiredDate() {
        return Number(localStorage.getItem('cookie-confirm-expired'));
    },

    createListeners: function () {
        const self = this;
        this.notificationButton.on('click', function () {
            self.hideNotification();
            self.setExpiredDate();
        });
        this.notificationCloseButton.on('click', function () {
            self.hideNotification();
        });
    },

    showNotification: function () {
        this.notification.show();
    },

    hideNotification: function () {
        this.notification.hide();
    },

    setExpiredDate: function () {
        return localStorage.setItem('cookie-confirm-expired', String(this.initExpiredDate().getTime()));
    },

    initExpiredDate: function () {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }
};
cookieNotification.init();