var ajaxLoadSpinner = {
    hide: function() {
        return true;
    },
    show: function() {
        return true;
    }
};
$(function () {
    // jquery.animateSprite.min.js is required


    if ($('.ajax-loader').length) {
        var ajax_loader = $('.ajax-loader');
        // инициируем спрайт-анимацию для ajax-крутилок
        ajax_loader.animateSprite({
            columns: 12,
            fps: 10,
            loop: true,
            animations: {
                spin: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            },
            complete: function() {
                return false;
            }
        });

        // тут же останавливаем спрайт-анимацию ajax-крутилки,
        // а в нужный момент у нужной ajax-крутилки запустим
        ajax_loader.animateSprite('stop');

        // Включение выключение анимации у нужной ajax-крутилки
        // metaformshandler.js is required
        // jquery.animateSprite.min.js is required
        ajaxLoadSpinner = {
            hide: function(spinner) {
                spinner.stop().animate({opacity: 0}, 300, 'swing', function() {
                    $(this).animateSprite('stop');
                });
                return true;
            },
            show: function(spinner) {
                spinner.stop().animate({opacity: 1}, 300, 'swing').animateSprite('resume');
                return true;
            }
        };
    } // <- Спрайт-анимация для ajax-крутилки

    //  Главный слайдер
    var swiper = new Swiper(".swiper-container", {
        pagination: ".swiper-pagination",
        paginationClickable: true,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        spaceBetween: 0,
        autoplay: 5000,
        loop: true,
        grabCursor: true,
        autoplayDisableOnInteraction: false,
    });

    /** Слайдер карточек товаров **/
    var swiper2 = new Swiper(".swiper-container_2", {
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        paginationClickable: true,
        loop: true,
        autoplay: false,
        slidesPerView: 3,
        spaceBetween: 30,
        grabCursor: true,
        autoplayDisableOnInteraction: false,
        breakpoints: {
            479: {
                slidesPerView: 1,
                spaceBetweenSlides: 0,
            },
            767: {
                slidesPerView: 2,
                spaceBetweenSlides: 30,
            },
            991: {
                slidesPerView: 3,
                spaceBetweenSlides: 30,
            },
        },
    });

    /** Мобильный слайдер карточки товара **/
    var swiper3 = new Swiper(".swiper-container_3", {
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        pagination: ".swiper-pagination",
        paginationClickable: true,
        loop: true,
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 0,
        grabCursor: true,
        autoplayDisableOnInteraction: false,
        breakpoints: {
            479: {
                slidesPerView: 1,
                spaceBetweenSlides: 0,
            },
        },
    });

    /** Галерея для текстовых страницы **/
    $(".popup_gallery").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError:
                '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return "";
            },
        },
    });

    /** Вызов модальных окон **/
    $(".default_popup").magnificPopup({
        type: "inline",
        preloader: false,
        focus: "#name",
        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function () {
                if ($(window).width() < 767) {
                    this.st.focus = false;
                } else {
                    this.st.focus = "#name";
                }
            },
        },
    });

    /** Горизонтальная галерея-слайдер **/
    $(document).ready(function () {
        $("#image_gallery").lightSlider({
            gallery: true,
            item: 1,
            thumbItem: 5,
            slideMargin: 0,
            speed: 500,
            auto: false,
            loop: true,
            thumbMargin: 25,
            onSliderLoad: function () {
                $("#image_gallery").removeClass("cS-hidden");
            },
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        thumbItem: 4,
                        thumbMargin: 15,
                    },
                },
            ],
        });
    });

    /** Коллбэк на ресайз окна **/
    var onWindowResizeStop = function (callback) {
        var rtime = new Date();
        var timeout = false;
        var delta = 100;
        $(window).resize(function () {
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(windowResizeEnd, delta);
            }
        });

        function windowResizeEnd() {
            if (new Date() - rtime < delta) {
                setTimeout(windowResizeEnd, delta);
            } else {
                timeout = false;

                callback();
            }
        }
    };


    var $search = $(".header__search");
    if ($search.length) {
        var $input = $search.find("input"),
            url = $search.find("form").attr("action") + "/sagest/",
            $result = $search.find(".header__search_results"),
            timer = null;

        $input.on("input change", function (e) {
            var val = this.value;
            if (val.length > 3) {
                clearTimeout(timer);
                timer = setTimeout(function () {
                    $.getJSON(url, { q: val }, function (resp) {
                        if (resp.success) {
                            $result.html(resp.html).show();
                        } else {
                            $result.html("").hide();
                        }
                    });
                }, 300);
            } else {
                $result.html("").hide();
            }
        });
    }

});



$(function () {
    MetaFormsHandler.bind({
        target: "form.js-ajax-form"
    });

    if ($(".arrow_top_button").length) {
        var load = true,
            page = 1;

        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $(".arrow_top_button").fadeIn();
            } else {
                $(".arrow_top_button").fadeOut();
            }

            if ($(".pagination").length) {

                var sort = location.search.substring(1).split('=')[1];

                var $pag = $(".pagination"),
                    downPos = parseInt(
                        $(this).scrollTop() + $(window).height()
                    ),
                    pagPos = parseInt($pag.offset().top),
                    // link = $pag.attr("data-uri"),
                    link = location.pathname,
                    pageCount = $pag.attr("data-page_count");
                if (downPos > pagPos) {
                    if (load) {
                        load = false;
                        page++;
                        if (page <= pageCount) {
                            $.ajax({
                                url: link + "page" + page + "/",
                                data: {sort: sort}
                            }).done(function (answ) {
                                if (downPos > pagPos) {
                                    var lastPos = $(window).scrollTop();
                                    $('#paginator-loading').children().last()[0].scrollIntoView({block: "end"});
                                }
                                $(answ).appendTo("#paginator-loading");
                                window.scrollTo(0, lastPos);
                                load = true;
                            });
                        }
                    }
                }
            }
        });

        $(".arrow_top_button").on("click", function () {
            $("html, body").animate(
                {
                    scrollTop: 0,
                },
                600
            );
            return false;
        });
    }

    $(".video_popup").magnificPopup({
        type: "inline",
        preloader: false,
        focus: "#name",
        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function () {
                if ($(window).width() < 767) {
                    this.st.focus = false;
                } else {
                    this.st.focus = "#name";
                }
            },
            open: function () {
                var $context = $(this.currItem.el.context),
                    $content = $(this.content[0]);
                video = $context.attr("data-video");

                $content.find("iframe").attr("src", video);
            },
        },
    });

    var initFileUploader = function () {
        var $fileUploader = $("#file_uploader");

        if ($fileUploader.length) {
            var updateFilesIdentify = function () {
                var input = $fileUploader.find("#files"),
                    newValues = [];

                input.val("");

                $fileUploader.find(".js-file_uploader__file").each(function () {
                    newValues.push({
                        identify: $(this).attr("data-identify"),
                        path: $(this).attr("data-file_path"),
                    });
                });

                input.val(JSON.stringify(newValues));
            };

            var $fileInfoTemplate = $fileUploader.find(
                "#js-file_uploader__file"
            );

            $fileUploader
                .find("#file_uploader__input")
                .fileupload({
                    dataType: "json",
                    maxChunkSize: 5000000, // 5 MB
                    url: "/file-upload/",
                    paramName: "files[]",
                    add: function (e, data) {
                        var identify = null,
                            $item = null;

                        $.ajax({
                            async: false,
                            type: "POST",
                            dataType: "json",
                            url: "/file-upload/get-identify/",
                            success: function (response) {
                                if (response.success) {
                                    identify = response.identify;

                                    $item = $fileInfoTemplate.clone();
                                    $item
                                        .removeAttr("id")
                                        .addClass("js-file_uploader__file")
                                        .insertAfter($fileInfoTemplate)
                                        .attr("data-identify", identify)
                                        .show();

                                    $item
                                        .find(".js-file_uploader__file_name")
                                        .text(data.files[0].name);

                                    data.files[0].identify = identify;
                                } else {
                                    alert(response.message);
                                }
                            },
                        });

                        if (!identify) {
                            data.abort();
                            return false;
                        }

                        data.formData = {identify: identify};

                        if (e.isDefaultPrevented()) {
                            return false;
                        }
                        if (
                            data.autoUpload ||
                            (data.autoUpload !== false &&
                                $(this).fileupload("option", "autoUpload"))
                        ) {
                            data.process().done(function () {
                                var jqXHR = data.submit();

                                $item
                                    .find(".js-file_uploader__file_remove")
                                    .on("click", function (e) {
                                        e.preventDefault();

                                        $item.remove();
                                        jqXHR.abort();
                                        updateFilesIdentify();
                                    });
                            });
                        }
                    },
                })
                .bind("fileuploadprogress", function (e, data) {
                    var progress = parseInt(
                        (data.loaded / data.total) * 100,
                        10
                    );

                    $fileUploader
                        .find(
                            ".js-file_uploader__file[data-identify=" +
                            data.files[0].identify +
                            "]"
                        )
                        .find(".js-file_uploader__file_percent")
                        .text("(Загрузка - " + progress + "%)");
                })
                .bind("fileuploadfail", function (e, data) {
                    console.log("fail");
                })
                .bind("fileuploadchunkfail", function (e, data) {
                    console.log("chunk fail");
                })
                .bind("fileuploaddone", function (e, data) {
                    var id = data.files[0].identify,
                        $item = $fileUploader.find(
                            ".js-file_uploader__file[data-identify=" + id + "]"
                        );

                    if (data.result.files[0].hasOwnProperty("error")) {
                        alert(
                            "Ошибка загрузки файла " + data.result.files[0].name
                        );
                        $item.remove();
                    } else {
                        $item.attr("data-file_path", data.result.files[0].url);

                        $item
                            .find(".js-file_uploader__file_remove")
                            .text("Удалить");

                        updateFilesIdentify();
                    }
                });
        }
    };
    initFileUploader();
});